import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import loadEmployer, { loadEmployerData } from '../api/loadEmployer';
import { useAppContext } from '../components/AppContext';
import getCurrentEmployerId from '../selectors/getCurrentEmployerId';

type Urls =
  | '/verify_account'
  | '/company_contact'
  | '/company_location'
  | '/company_size'
  | '/company_industry'
  | '/employer_information'
  | '/confirmation';

interface UseNavigationReturn {
  jbUrl: string
  redirectTo: (url: Urls, noUser?: boolean) => void
  utm: string
  urlBase64?: string
}

interface UseNavigation {
  (): UseNavigationReturn
}

const useNavigation: UseNavigation = () => {
  const { state, setState } = useAppContext();
  const location = useLocation();
  const { push } = useHistory();
  const { urlBase64 } = useParams<{ urlBase64?: string }>();
  const jbUrl = atob(urlBase64 || '');
  const { search } = useLocation();
  const parts = search.replace(/^\?/, '').split('&');
  const utm = (parts.find((p = '') => p.match('utm_campaign=')) || '').split('=').pop() || '';

  const redirectTo = (url: Urls, noUser?: boolean) => push(`${url}/${noUser ? btoa('no-user') : urlBase64}${location.search}`);

  useEffect(() => {
    if (!state.employer && state.employerProfile) {
      return loadEmployerData({
        employerID: getCurrentEmployerId(state),
        onSuccess: (res) => {
          setState({ ...state, employer: res });
        },
      });
    }
    if (jbUrl && jbUrl !== 'no-user' && !state.employerProfile) {
      return loadEmployer({
        employerID: jbUrl,
        onSuccess: (res) => {
          setState({ ...state, employerProfile: res });
        },
      });
    }
  }, [jbUrl, state.employerProfile]);

  if (!urlBase64) redirectTo('/verify_account');

  return {
    jbUrl,
    redirectTo,
    utm,
    urlBase64,
  };
};

export default useNavigation;
