import MobileStepper from 'housecall-ui/dist/DesignSystem/Navigation/Steppers/MobileStepper';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useAppStyles from '../../hooks/useAppStyles';

const useSteps = (pathname: string): number | 'no-match' => {
  switch (true) {
    case /verify_account/.test(pathname):
      return 0;
    case /company_contact/.test(pathname):
      return 1;
    case /company_location/.test(pathname):
      return 2;
    case /company_size/.test(pathname):
      return 3;
    case /company_industry/.test(pathname):
      return 4;
    case /employer_information/.test(pathname):
      return 5;
    case /confirmation/.test(pathname):
      return 6;
    default:
      return 'no-match';
  }
};

const Stepper = () => {
  const { stepper } = useAppStyles();
  const { location } = useHistory();
  const step = useSteps(location.pathname);

  if (step === 'no-match') return null;

  return (<MobileStepper steps={6} position="static" className={stepper} activeStep={step} />);
};

export default Stepper;
