import React, { ReactNode, FC } from 'react';
import Typography from 'housecall-ui/dist/DesignSystem/DataDisplay/Typography/Typography';
import useAppStyles from '../../hooks/useAppStyles';

const Title: FC<{ children: ReactNode }> = ({ children }) => {
  const { onboardingTitle, onboardingSubTitle } = useAppStyles();
  return (
    <>
      <Typography variant="h1" component="span" className={onboardingTitle}>
        Employer sign up
      </Typography>
      <div className={onboardingSubTitle}>
        {children}
      </div>
    </>

  );
};

export default Title;
