import React, { useMemo } from 'react';
import { ArrowBack } from '@material-ui/icons';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';

const BackButton: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { goBack } = useHistory();
  const { pathname } = useLocation();
  const excludedUrls = [/^\/verify_account\/.+/, /^\/confirmation\/.+/];
  const isExcluded = useMemo(() => excludedUrls.some((re) => re.test(pathname)), [pathname]);

  if (!isMobile || isExcluded) return null;

  return (
    <ArrowBack onClick={() => goBack()} />
  );
};

export default BackButton;
