import React, { ReactNode } from 'react';
import OnboardingToolbar from 'housecall-ui/dist/DesignSystem/Navigation/TopNavigation/OnboardingToolbar';
import HousecallThemeProvider from 'housecall-ui/dist/DesignSystem/Styles/HousecallThemeProvider';
import TradeAcademyLogoText from 'housecall-ui/dist/IconsAndImages/Logos/TradeAcademyLogoText';
import Grid from 'housecall-ui/dist/DesignSystem/Layout/Grid/Grid';
import CssBaseline from 'housecall-ui/dist/utils/CssBaseline';
import useAppStyles from '../hooks/useAppStyles';
import BackButton from './atoms/BackButton';

interface LayoutProps {
  children: ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { bodyRow, navbar } = useAppStyles();

  return (
    <HousecallThemeProvider>
      <CssBaseline />
      <Grid container alignItems="center" justify="center">
        <Grid item xs={10}>
          <OnboardingToolbar
            position="fixed"
            leftIcon={<BackButton />}
            centerChildren={<TradeAcademyLogoText width={220} />}
            className={navbar}
          />
        </Grid>
        <Grid item xs={10} className={bodyRow}>
          {children}
        </Grid>
      </Grid>
    </HousecallThemeProvider>
  );
};

export default Layout;
