import React, { useEffect, useState } from 'react';
import TextField from 'housecall-ui/dist/DesignSystem/Inputs/TextFields/TextField';
import Introduction from '../Introduction';
import getCurrentEmployerData from '../../selectors/getCurrentEmployerData';
import upsertEmployer from '../../api/upsertEmployer';
import useNavigation from '../../hooks/useNavigation';
import { useAppContext } from '../AppContext';
import getCurrentEmployerId from '../../selectors/getCurrentEmployerId';

const CompanyName = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { state, setState, track } = useAppContext();
  const { utm, redirectTo, jbUrl } = useNavigation();
  const organizationName = state.employer?.organizationName || '';
  const uuid = state.employerProfile?.uuid || '';
  const isVerified = Boolean(jbUrl && jbUrl !== 'no-user');

  const handleSubmit = () => {
    setLoading(true);
    upsertEmployer({
      ...(getCurrentEmployerId(state) && { employerID: getCurrentEmployerId(state) }),
      body: {
        ...(state.employerProfile?.id && { jobsite_employer_profile_id: state.employerProfile.id }),
        utm,
        organization_name: inputValue,
        onboarding_step: 'company_name',
        uuid,
      },
      onSuccess: (res) => {
        setState({ ...state, employer: res });
        setLoading(false);
        redirectTo('/company_contact');
      },
      onError: () => setLoading(false),
      useReferer: true,
    });
  };

  useEffect(() => {
    setInputValue(getCurrentEmployerData(state, 'organizationName'));
  }, [organizationName]);

  useEffect(() => {
    if ((!!uuid || !isVerified) && track) {
      track.pageView('employer signup', {
        jobboardio_uuid: uuid,
        is_verifying_profile: isVerified,
      });
    }
  }, [uuid, track?.pageView]);

  return (
    <Introduction
      title="What's your company name?"
      body="Verify your company to start posting jobs for free"
      loading={loading}
      onSubmit={handleSubmit}
      disabled={inputValue.length < 3}
    >
      <TextField
        fullWidth
        label="Company name"
        error={Boolean(inputValue && inputValue.length < 3)}
        value={inputValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
        autoFocus
      />
    </Introduction>
  );
};

export default CompanyName;
