import React, { useState, useEffect } from 'react';
import Grid from 'housecall-ui/dist/DesignSystem/Layout/Grid/Grid';
import Select from 'housecall-ui/dist/DesignSystem/Inputs/Selects/Select';
import MenuItem from 'housecall-ui/dist/DesignSystem/Navigation/Menus/MenuItem';
import FormControl from 'housecall-ui/dist/DesignSystem/Inputs/FormControls/FormControl';

import FilledFlatInput from 'housecall-ui/dist/DesignSystem/Inputs/TextFields/FilledFlatInput';
import Introduction from '../Introduction';

import { useAppContext } from '../AppContext';

import useNavigation from '../../hooks/useNavigation';
import upsertEmployer from '../../api/upsertEmployer';
import getCurrentEmployerId from '../../selectors/getCurrentEmployerId';
import getCurrentEmployerData from '../../selectors/getCurrentEmployerData';

const items = [
  'Air Duct Cleaning',
  'Appliances',
  'Automotive',
  'Carpet Cleaning',
  'Electrical',
  'Garage',
  'General Contractor',
  'Handyman',
  'Heating & Air Conditioning',
  'Home Cleaning',
  'Landscaping & Lawn',
  'Pest Control',
  'Plumbing',
  'Window & Exterior Cleaning',
  'Other',
];

const CompanySize: React.FC = () => {
  const { state, setState } = useAppContext();
  const [industry, setIndustry] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { redirectTo } = useNavigation();
  const uuid = state.employerProfile?.uuid || '';

  useEffect(() => {
    setIndustry(getCurrentEmployerData(state, 'industry'));
  }, [state]);

  const handleClick = (s: string) => setIndustry(s);

  const handleSubmit = () => {
    setLoading(true);
    upsertEmployer({
      employerID: getCurrentEmployerId(state),
      body: { industry, onboarding_step: 'industry', uuid },
      onSuccess: (res) => {
        setState({ ...state, employer: res });
        setLoading(false);
        redirectTo('/employer_information');
      },
      onError: () => setLoading(false),
    });
  };

  return (
    <Introduction
      title="What is your primary industry?"
      onSubmit={() => handleSubmit()}
      loading={loading}
      disabled={!industry}
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FormControl fullWidth style={{ minWidth: '250px' }}>
            <Select
              autoFocus
              value={industry}
              displayEmpty
              onChange={({
                target: { value },
              }: {
                target: { value: string };
              }) => handleClick(value)}
              input={<FilledFlatInput />}
            >
              <MenuItem value="">
                <em>Select an option</em>
              </MenuItem>
              {items.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Introduction>
  );
};

export default CompanySize;
