import React, { useEffect } from 'react';
import Introduction from '../Introduction';
import employerIDStorage from '../../lib/employerIDStorage';

const { remove } = employerIDStorage();

const Confirmation = () => {
  useEffect(() => {
    remove();
  }, []);

  return (
    <Introduction
      title="Congratulations, your company has been verified."
      body={"We'll send you an email with the next steps on completing your profile."}
    />
  );
};

export default Confirmation;
