import React, { useState, useEffect, useRef } from 'react';
import GoogleAutocomplete from 'housecall-ui/dist/DesignSystem/Inputs/AutoComplete/GoogleAutocomplete';
import Grid from 'housecall-ui/dist/DesignSystem/Layout/Grid/Grid';
import TextField from 'housecall-ui/dist/DesignSystem/Inputs/TextFields/TextField';
import Introduction from '../Introduction';
import { useAppContext } from '../AppContext';
import useNavigation from '../../hooks/useNavigation';
import upsertEmployer from '../../api/upsertEmployer';
import getCurrentEmployerId from '../../selectors/getCurrentEmployerId';
import getCurrentEmployerData from '../../selectors/getCurrentEmployerData';

interface GoogleAddressLocation {
  city: string
  country: string
  formatted_address: string
  formatted_phone_number: string
  latitude: number
  longitude: number
  name: string
  placeId: string
  postal_code: string
  state: string
  street: string
}

const CompanyLocation: React.FC = () => {
  const { state: stateContext, setState: setStateContext } = useAppContext();
  const [googleAddress, setGoogleAddress] = useState<null | GoogleAddressLocation>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { redirectTo } = useNavigation();
  const [address, setAddress] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const uuid = stateContext.employerProfile?.uuid || '';
  const postalCodeRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setCity(getCurrentEmployerData(stateContext, 'city'));
    setState(getCurrentEmployerData(stateContext, 'state'));
    setPostalCode(getCurrentEmployerData(stateContext, 'zipCode'));
    setAddress(getCurrentEmployerData(stateContext, 'address'));
  }, [stateContext]);

  const handleSubmit = () => {
    setLoading(true);
    upsertEmployer({
      employerID: getCurrentEmployerId(stateContext),
      body: {
        zip_code: postalCode,
        city,
        state,
        lat: googleAddress?.latitude || 0,
        lng: googleAddress?.longitude || 0,
        location: googleAddress?.placeId || '',
        onboarding_step: 'address',
        address,
        address_line2: '',
        uuid,
      },
      onSuccess: (res) => {
        setStateContext({ ...stateContext, employer: res });
        setLoading(false);
        redirectTo('/company_size');
      },
      onError: () => setLoading(false),
    });
  };

  const autoCompleteFields = (res: GoogleAddressLocation) => {
    setGoogleAddress(res);
    setAddress(res?.street || '');
    setCity(res?.city || '');
    setPostalCode(res?.postal_code || '');
    setState(res?.state || '');
    postalCodeRef.current?.focus();
  };

  return (
    <Introduction
      title={"Where's your company located?"}
      body="This will allow us to match you with the best candidates in your area"
      loading={loading}
      onSubmit={() => handleSubmit()}
      disabled={!googleAddress}
    >
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <GoogleAutocomplete
            defaultValue={address}
            autoCompleteType="address"
            onAutoComplete={(res: GoogleAddressLocation) => {
              autoCompleteFields(res);
            }}
            fullWidth
            onTextFieldChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setGoogleAddress(null);
              setAddress(e.target.value);
              setCity('');
              setPostalCode('');
              setState('');
            }}
           // Prevents browsers' autofill
            label={'\u0410ddress'}
            // This is for preventing accessibility issues
            aria-label="Address"
            autoFocus
            onKeyDown={(e: KeyboardEvent) => {
              // This prevents form submission until an address is autofilled
              if (e.keyCode === 13 && googleAddress === null) {
                e.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="City"
            value={city}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="State"
            value={state}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Postal code"
            value={postalCode}
            inputRef={postalCodeRef}
            inputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>
    </Introduction>
  );
};

export default CompanyLocation;
