import React, { ReactNode } from 'react';
import Button from 'housecall-ui/dist/DesignSystem/Inputs/Buttons/Button';
import HcUiIntroduction from 'housecall-ui/dist/HousecallComponents/Onboarding/Introduction/Introduction';
import JanellePersona from 'housecall-ui/dist/HousecallComponents/Onboarding/Personas/JenellePersona';
import Grid from 'housecall-ui/dist/DesignSystem/Layout/Grid/Grid';
import useAppStyles from '../hooks/useAppStyles';
import Title from './atoms/Title';
import Stepper from './atoms/Stepper';

interface IntroductionProps {
  body?: string
  children?: ReactNode
  disabled?: boolean
  label?: string
  loading?: boolean
  onSubmit?: () => void
  title: string
  width?: number
}

const Introduction: React.FC<IntroductionProps> = ({
  children,
  width,
  loading,
  title,
  body,
  onSubmit,
  disabled,
  label,
}) => {
  const { actions, formContent } = useAppStyles();
  return (
    <Grid>
      <HcUiIntroduction
        width={width}
        image={<JanellePersona />}
        title={<Title>{title}</Title>}
        body={body}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (onSubmit) onSubmit();
          }}
        >
          {!!children && (
            <>
              <Grid item xs={12} md={4} className={formContent}>
                {children}
              </Grid>

              <Grid item xs={12} md={4} className={actions}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                  disabled={disabled || loading}
                  id="btnNext"
                >
                  {(label || 'NEXT')}
                </Button>

                <Stepper />
              </Grid>
            </>
          )}
        </form>
      </HcUiIntroduction>
    </Grid>
  );
};

export default Introduction;
