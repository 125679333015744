import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CompanyContact from './onboarding/CompanyContact';
import CompanyLocation from './onboarding/CompanyLocation';
import CompanyName from './onboarding/CompanyName';
import CompanySize from './onboarding/CompanySize';
import CompanyIndustry from './onboarding/CompanyIndustry';
import Confirmation from './onboarding/Confirmation';
import EmployerInformation from './onboarding/EmployerInformation';
import ErrorPage404 from './atoms/ErrorPage404';
import NoUser from './onboarding/NoUser';

const Routes = () => (
  <Switch>
    <Route path="/verify_account/:urlBase64" exact component={CompanyName} />
    <Route path="/company_contact/:urlBase64" exact component={CompanyContact} />
    <Route path="/company_location/:urlBase64" exact component={CompanyLocation} />
    <Route path="/company_size/:urlBase64" exact component={CompanySize} />
    <Route path="/company_industry/:urlBase64" exact component={CompanyIndustry} />
    <Route path="/employer_information/:urlBase64" exact component={EmployerInformation} />
    <Route path="/confirmation/:urlBase64" component={Confirmation} />
    <Route path="/404" exact component={ErrorPage404} />
    <Route path="*" component={NoUser} />
  </Switch>
);

export default Routes;
