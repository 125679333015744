import React, { useState, useEffect } from 'react';
import Grid from 'housecall-ui/dist/DesignSystem/Layout/Grid/Grid';
import TextField from 'housecall-ui/dist/DesignSystem/Inputs/TextFields/TextField';
import Introduction from '../Introduction';
import getCurrentEmployerData from '../../selectors/getCurrentEmployerData';
import getCurrentEmployerId from '../../selectors/getCurrentEmployerId';
import upsertEmployer from '../../api/upsertEmployer';
import useNavigation from '../../hooks/useNavigation';
import { useAppContext } from '../AppContext';

const EmployerInformation = () => {
  const { state, setState } = useAppContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');
  const [values, setValues] = useState<{
    first_name: string
    last_name: string
    email: string
  }>({
    email: '',
    first_name: '',
    last_name: '',
  });
  const { redirectTo } = useNavigation();
  const uuid = state.employerProfile?.uuid || '';

  useEffect(() => {
    setValues((v) => ({
      ...v,
      first_name: getCurrentEmployerData(state, 'firstName'),
      last_name: getCurrentEmployerData(state, 'lastName'),
      email: getCurrentEmployerData(state, 'email'),
    }));
  }, [state]);

  const handleSubmit = () => {
    setLoading(true);
    upsertEmployer({
      employerID: getCurrentEmployerId(state),
      body: { ...values, onboarding_step: 'email_and_name', uuid },
      onSuccess: (res) => {
        setState({ ...state, employer: res });
        setLoading(false);
        redirectTo('/confirmation');
      },
      onError: ([errorMessage = '']) => {
        setLoading(false);
        setApiError(errorMessage);
      },
    });
  };

  const emailError = values.email !== '' && !new RegExp(/.+@.+\..+/).test(values.email);

  return (
    <Introduction
      title="You're almost there..."
      body="Your login details will be emailed to this address."
      loading={loading}
      onSubmit={handleSubmit}
      disabled={emailError || !values.email || !values.first_name || !values.last_name}
      label="Submit"
    >
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="First name"
            value={values.first_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValues({ ...values, first_name: e.target.value });
            }}
            autoFocus
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Last name"
            value={values.last_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValues({ ...values, last_name: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            error={emailError || Boolean(apiError)}
            helperText={apiError || ''}
            value={values.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValues({ ...values, email: e.target.value });
              if (apiError) setApiError('');
            }}
          />
        </Grid>
      </Grid>
    </Introduction>
  );
};

export default EmployerInformation;
