import React, { useEffect } from 'react';
import useNavigation from '../../hooks/useNavigation';

const NoUser = () => {
  const { urlBase64, redirectTo } = useNavigation();

  useEffect(() => {
    if (!urlBase64) redirectTo('/verify_account', true);
  }, [urlBase64]);

  return <div>Loading...</div>;
};

export default NoUser;
