import getCurrentUrl from '../lib/getCurrentUrl';
import { Destructor } from './models/Destructor';
import { Employer } from './models/Employer';
import getAmplitudeSessionID from '../lib/getAmplitudeSessionID';
import getAnalyticsUserID from '../lib/getAnalyticsUserID';

interface UpdateEmployerParams {
  employerID?: number | null
  body: {
    mobileNumber?: string
    state?: string
    city?: string
    zip_code?: string
    lat?: number
    lng?: number
    location?: string
    number_of_employees?: number
    first_name?: string
    last_name?: string
    email?: string
    onboarding_step:
    | 'company_name'
    | 'phone_number'
    | 'address'
    | 'email_and_name'
    | 'number_of_employees'
    | 'industry'
    jobsite_employer_profile_id?: number | null
    utm?: string
    organization_name?: string
    address?: string
    address_line2?: string
    industry?: string
    uuid: string
  }
  onSuccess: (employer: Employer) => void
  onError?: (error: string[]) => void
  useReferer?: boolean
}

interface UpdateEmployer {
  (params: UpdateEmployerParams): void | Destructor
}

const upsertEmployer: UpdateEmployer = (p) => {
  const controller = new AbortController();
  const ref = getCurrentUrl(p.useReferer);
  const sessionID = getAmplitudeSessionID();
  const analyticsUserID = getAnalyticsUserID();
  try {
    fetch(`${process.env.REACT_APP_GROWTH_SERVER_URL || ''}/jobsite/v1/employers${p.employerID ? `/${p.employerID}` : ''}`, {
      method: p.employerID ? 'PATCH' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-ANALYTICS-ID': analyticsUserID,
        'X-REFERRER': ref,
        'Amplitude-Session-Id': sessionID,
      },
      signal: controller.signal,
      body: JSON.stringify(p.body),
    }).then(async (res) => {
      const data = await res.json();
      if (res.ok) {
        p.onSuccess(data);
      } else if (p.onError) p.onError(data.errors || []);
    });
  } catch (e) {
    if (p.onError) return p.onError(e.message);
  }
  return () => controller.abort();
};

export default upsertEmployer;
