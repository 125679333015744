import React, { useEffect, useState } from 'react';
import TextField from 'housecall-ui/dist/DesignSystem/Inputs/TextFields/TextField';
import Introduction from '../Introduction';
import getCurrentEmployerId from '../../selectors/getCurrentEmployerId';
import upsertEmployer from '../../api/upsertEmployer';
import useNavigation from '../../hooks/useNavigation';
import { useAppContext } from '../AppContext';
import getCurrentEmployerData from '../../selectors/getCurrentEmployerData';

const CompanyContact = () => {
  const { state, setState } = useAppContext();
  const [inputValue, setInputValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { redirectTo } = useNavigation();
  const uuid = state.employerProfile?.uuid || '';

  useEffect(() => {
    setInputValue(getCurrentEmployerData(state, 'mobileNumber'));
  }, [state]);

  const handleSubmit = () => {
    setLoading(true);
    upsertEmployer({
      employerID: getCurrentEmployerId(state),
      body: { mobileNumber: inputValue, onboarding_step: 'phone_number', uuid },
      onSuccess: (res) => {
        setState({ ...state, employer: res });
        setLoading(false);
        redirectTo('/company_location');
      },
      onError: () => setLoading(false),
    });
  };

  const isPhoneValid = inputValue.replace(/\D/g, '').length < 10;

  return (
    <Introduction
      title="What's your phone number?"
      body="So that candidates can get in touch with you"
      loading={loading}
      onSubmit={() => handleSubmit()}
      disabled={isPhoneValid}
    >
      <TextField
        label="Company phone"
        mask="phone"
        value={inputValue}
        error={Boolean(inputValue && isPhoneValid)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
        autoFocus
      />
    </Introduction>
  );
};

export default CompanyContact;
