import { Employer } from './models/Employer';
import { Destructor } from './models/Destructor';

export interface EmployerProfile {
  id: number
  state: string
  url: string
  uuid: string
  verified: boolean
  jobsiteEmployerId: number
}

interface LoadEmployerParams {
  employerID?: string
  onError?: (error: string) => void
  onSuccess: (employer: EmployerProfile) => void
}

interface LoadEmployerDataParams {
  employerID?: number | null
  onError?: (error: string) => void
  onSuccess: (employer: Employer) => void
}

interface LoadEmployer {
  (params: LoadEmployerParams): void | Destructor
}

interface LoadEmployerData {
  (params: LoadEmployerDataParams): void | Destructor
}

export const getData = (url: string) => {
  const controller = new AbortController();
  return fetch(
    url,
    {
      method: 'GET',
      signal: controller.signal,
    },
  );
};

const loadEmployer: LoadEmployer = ({ employerID, onSuccess, onError }) => {
  if (!employerID) return;
  const controller = new AbortController();
  try {
    getData(`${process.env.REACT_APP_GROWTH_SERVER_URL || ''}/jobsite/v1/employer_profiles/${employerID}`)
      .then((res) => res.json()
        .then((json: EmployerProfile) => onSuccess(json)));
  } catch (e) {
    if (onError) return onError(e.message);
  }
  return () => controller.abort();
};

export const loadEmployerData: LoadEmployerData = ({ employerID, onSuccess, onError }) => {
  if (!employerID) return;
  const controller = new AbortController();
  try {
    getData(`${process.env.REACT_APP_GROWTH_SERVER_URL || ''}/jobsite/v1/employers/${employerID}`)
      .then((res) => res.json()
        .then((json: Employer) => onSuccess(json)));
  } catch (e) {
    if (onError) return onError(e.message);
  }
  return () => controller.abort();
};

export default loadEmployer;
