import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppProvider from './AppProvider';
import Layout from './Layout';
import Routes from './Routes';

const App = () => (
  <AppProvider>
    <Router>
      <Layout>
        <Routes />
      </Layout>
    </Router>
  </AppProvider>
);

export default App;
