import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import getAnalyticsUserID from '../lib/getAnalyticsUserID';

export interface UserAnalyticsReturn<T> {
  pageView: (pageName: string, options: T) => void
}

interface SetAnalyticsReady {
  (ready: boolean): void
}

const useAnalytics = <T extends Object>(
  analyticsReady: boolean,
  setAnalyticsReady: SetAnalyticsReady): UserAnalyticsReturn<T> => {
  const [uuid, setUuid] = useState('');

  useEffect(() => {
    if (!analyticsReady) {
      analytics.ready(() => {
        setAnalyticsReady(true);
      });
    } else {
      setUuid(getAnalyticsUserID() || uuidv4());
    }
  }, [analyticsReady]);

  useEffect(() => {
    if (analyticsReady && uuid) {
      analytics.identify(uuid);
    }
  }, [analyticsReady, uuid]);

  const pageView = useCallback((pageName: string, options: T) => {
    if (analyticsReady) {
      analytics.page(pageName, options);
    }
  }, [analyticsReady]);

  return { pageView };
};

export default useAnalytics;
