import { makeStyles } from '@material-ui/core';

export default makeStyles({
  actions: {
    marginTop: '25px',
    alignContent: 'center',
    textAlign: 'center',
  },
  stepper: {
    justifyContent: 'center',
    marginTop: 30,
  },
  bodyRow: { marginTop: 95 },
  backButton: {
    position: 'relative',
    left: '-50px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  navbar: {
    backgroundColor: '#000',
    color: '#FFF',
  },
  onboardingTitle: {
    fontWeight: 700,
    fontSize: 32,
    marginTop: 10,
  },
  onboardingSubTitle: {
    marginTop: 10,
  },
  formContent: {
    marginTop: 10,
  },
});
