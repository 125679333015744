import { createContext, useContext } from 'react';
import { EmployerProfile } from '../api/loadEmployer';
import { Employer } from '../api/models/Employer';
import { UserAnalyticsReturn } from '../hooks/useAnalytics';
import employerIDStorage from '../lib/employerIDStorage';

const { get } = employerIDStorage();

export interface AppState {
  employerProfile: EmployerProfile | null
  employer: Employer | null
  isAnalyticsReady: boolean
  employerID: null | number
}

export interface AppContextInterface {
  state: AppState
  setState: (state: AppState) => void
  track?: UserAnalyticsReturn<any>,
}

export const defaultState = (): AppContextInterface => ({
  state: {
    employer: null,
    employerProfile: null,
    isAnalyticsReady: false,
    employerID: get() ? Number(get()) : null,
  },
  setState: () => {},
});

export const AppContext = createContext<AppContextInterface>(defaultState());

export const useAppContext = () => useContext<AppContextInterface>(AppContext);
