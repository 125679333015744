import React, { ReactNode, useEffect, useState } from 'react';
import { AppContext, defaultState } from './AppContext';
import useAnalytics from '../hooks/useAnalytics';
import employerIDStorage from '../lib/employerIDStorage';

const { set } = employerIDStorage();

export interface AppStoreProps {
  children: ReactNode
}

const AppProvider: React.FC<AppStoreProps> = ({ children }) => {
  const [state, setState] = useState(defaultState().state);
  const track = useAnalytics(
    state.isAnalyticsReady,
    (val: boolean) => setState({ ...state, isAnalyticsReady: val }),
  );

  useEffect(() => {
    if (state.employer?.id && state.employer.id !== state.employerID) {
      set(state.employer.id);
    }
  }, [state.employer]);

  return (
    <AppContext.Provider value={{ state, setState, track }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
