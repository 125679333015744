import React, { useState, useEffect } from 'react';
import EmployeesSmall from 'housecall-ui/dist/IconsAndImages/Icons/EmployeesSmall';
import EmployeesMedium from 'housecall-ui/dist/IconsAndImages/Icons/EmployeesMedium';
import EmployeesLarge from 'housecall-ui/dist/IconsAndImages/Icons/EmployeesLarge';
import EmployeesXL from 'housecall-ui/dist/IconsAndImages/Icons/EmployeesXL';
import Grid from 'housecall-ui/dist/DesignSystem/Layout/Grid/Grid';
import IndustryCard from 'housecall-ui/dist/core/IndustrySelection/IndustryCard';
import { useAppContext } from '../AppContext';

import useNavigation from '../../hooks/useNavigation';
import Introduction from '../Introduction';
import upsertEmployer from '../../api/upsertEmployer';
import getCurrentEmployerId from '../../selectors/getCurrentEmployerId';
import getCurrentEmployerData from '../../selectors/getCurrentEmployerData';

const CompanySize: React.FC = () => {
  const { state, setState } = useAppContext();
  const [numberOfEmployees, setSelected] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { redirectTo } = useNavigation();
  const items = [
    { name: 'Just me', icon: <EmployeesSmall />, employees: 1 },
    { name: '2 - 5 Employees', icon: <EmployeesMedium />, employees: 2 },
    { name: '6 - 9 Employees', icon: <EmployeesLarge />, employees: 6 },
    { name: '10 +', icon: <EmployeesXL />, employees: 10 },
  ];
  const uuid = state.employerProfile?.uuid || '';

  useEffect(() => {
    setSelected(Number(getCurrentEmployerData(state, 'numberOfEmployees')));
  }, [state]);

  useEffect(() => {
    if (document !== undefined && numberOfEmployees) document.querySelector<HTMLInputElement>('#btnNext')?.focus();
  }, [numberOfEmployees]);

  const handleClick = (n: number) => () => setSelected(n);

  const handleSubmit = () => {
    setLoading(true);
    upsertEmployer({
      employerID: getCurrentEmployerId(state),
      body: { number_of_employees: numberOfEmployees, onboarding_step: 'number_of_employees', uuid },
      onSuccess: (res) => {
        setState({ ...state, employer: res });
        setLoading(false);
        redirectTo('/company_industry');
      },
      onError: () => setLoading(false),
    });
  };

  const isSelected = (n: number): boolean => Boolean(n && n === numberOfEmployees);

  return (
    <Introduction
      title="How big is your company?"
      body="Most candidates want to know how big a company is before they apply"
      loading={loading}
      onSubmit={handleSubmit}
      disabled={!numberOfEmployees}
    >
      <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
        {items.map((item) => (
          <Grid item xs={6} md={3} key={item.employees}>
            <IndustryCard
              name={item.name}
              isSelected={isSelected(item.employees)}
              onClick={handleClick(item.employees)}
              icon={item.icon}
            />
          </Grid>
        ))}
      </Grid>
    </Introduction>
  );
};

export default CompanySize;
